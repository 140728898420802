<template>
	<div class="px-0 md:px-32 xl:px-64 2xl:px-128">
		<div class="header">
			<nav
				:class="{ scrolled: !view.atTopOfPage }"
				class="fixed flex w-full glass text-white items-start justify-between py-10 top-0 left-0 animated h-64 px-10 md:px-32 xl:px-64 2xl:px-128"
			>
				<div class="one w-1/3 flex items-start">
					<div
						class="nav flex font-light tracking-wide content-center justify-center text-black items-start"
					>
						<nav class="header__nav block">
							<ul class="header__navbar">
								<li class="header__item">
									<a class="header__link">
										<transition name="slide-fade">
											<!-- Header Navigation Menu Icons -->
											<button
												class="header--button"
												v-if="show"
												key="on"
												@click="show = false"
											>
												<svg viewBox="0 0 24 24" class="header--icon">
													<title>Close</title>
													<path d="M0 0h24v24H0V0z" fill="none" />
													<path
														fill="currentColor"
														d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
													/>
												</svg>
											</button>
											<button
												class="header--button"
												v-else
												key="off"
												@click="show = true"
											>
												<svg viewBox="0 0 24 24" class="header--icon">
													<title>Navigation Menu</title>
													<path
														fill="currentColor"
														d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"
													/>
												</svg>
											</button>
										</transition>
									</a>
									<!-- Dropdown Menu -->
									<transition name="dropdown">
										<div
											class="dropdown__menu"
											v-bind:class="{ active: show }"
											v-if="show"
										>
											<ul class="dropdown__menu-nav">
												<li class="dropdown__menu-item" @click="show = false">
													<router-link
														to="/team"
														class="dropdown__menu-link"
														title="Team"
													>
														<!--<div class="dropdown__menu-svg">
                      <svg viewBox="0 0 496 512">
                        <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm128 421.6c-35.9 26.5-80.1 42.4-128 42.4s-92.1-15.9-128-42.4V416c0-35.3 28.7-64 64-64 11.1 0 27.5 11.4 64 11.4 36.6 0 52.8-11.4 64-11.4 35.3 0 64 28.7 64 64v13.6zm30.6-27.5c-6.8-46.4-46.3-82.1-94.6-82.1-20.5 0-30.4 11.4-64 11.4S204.6 320 184 320c-48.3 0-87.8 35.7-94.6 82.1C53.9 363.6 32 312.4 32 256c0-119.1 96.9-216 216-216s216 96.9 216 216c0 56.4-21.9 107.6-57.4 146.1zM248 120c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 144c-30.9 0-56-25.1-56-56s25.1-56 56-56 56 25.1 56 56-25.1 56-56 56z" />
                      </svg>
                    </div>-->
														<div class="dropdown__menu-text font-bold">
															Team
														</div>
													</router-link>
												</li>
												<li class="dropdown__menu-item" @click="show = false">
													<router-link
														to="/preisliste"
														class="dropdown__menu-link"
														title="Preisliste + Leistungen"
													>
														<!--<div class="dropdown__menu-svg">
                      <svg viewBox="0 0 496 512">
                        <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm128 421.6c-35.9 26.5-80.1 42.4-128 42.4s-92.1-15.9-128-42.4V416c0-35.3 28.7-64 64-64 11.1 0 27.5 11.4 64 11.4 36.6 0 52.8-11.4 64-11.4 35.3 0 64 28.7 64 64v13.6zm30.6-27.5c-6.8-46.4-46.3-82.1-94.6-82.1-20.5 0-30.4 11.4-64 11.4S204.6 320 184 320c-48.3 0-87.8 35.7-94.6 82.1C53.9 363.6 32 312.4 32 256c0-119.1 96.9-216 216-216s216 96.9 216 216c0 56.4-21.9 107.6-57.4 146.1zM248 120c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 144c-30.9 0-56-25.1-56-56s25.1-56 56-56 56 25.1 56 56-25.1 56-56 56z" />
                      </svg>
                    </div>-->
														<div class="dropdown__menu-text font-bold">
															Preisliste + Leistungen
														</div>
													</router-link>
												</li>
												<li class="dropdown__menu-item" @click="show = false">
													<router-link
														to="/buchung"
														class="dropdown__menu-link"
														title="Online Buchen"
													>
														<!--<div class="dropdown__menu-svg">
                      <svg viewBox="0 0 496 512">
                        <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm128 421.6c-35.9 26.5-80.1 42.4-128 42.4s-92.1-15.9-128-42.4V416c0-35.3 28.7-64 64-64 11.1 0 27.5 11.4 64 11.4 36.6 0 52.8-11.4 64-11.4 35.3 0 64 28.7 64 64v13.6zm30.6-27.5c-6.8-46.4-46.3-82.1-94.6-82.1-20.5 0-30.4 11.4-64 11.4S204.6 320 184 320c-48.3 0-87.8 35.7-94.6 82.1C53.9 363.6 32 312.4 32 256c0-119.1 96.9-216 216-216s216 96.9 216 216c0 56.4-21.9 107.6-57.4 146.1zM248 120c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 144c-30.9 0-56-25.1-56-56s25.1-56 56-56 56 25.1 56 56-25.1 56-56 56z" />
                      </svg>
                    </div>-->
														<div class="dropdown__menu-text font-bold">
															Online Buchen
														</div>
													</router-link>
												</li>
												<!--<li class="dropdown__menu-item" @click="show = false">
													<router-link
														to="/salon"
														class="dropdown__menu-link"
														title="Salon"
													>
									
														<div class="dropdown__menu-text font-bold">
															Salon + Galerie
														</div>
													</router-link>
												</li>-->
												<li class="dropdown__menu-item" @click="show = false">
													<router-link
														to="/jobs"
														class="dropdown__menu-link"
														title="Jobs"
													>
														<!--<div class="dropdown__menu-svg">
                      <svg viewBox="0 0 496 512">
                        <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm128 421.6c-35.9 26.5-80.1 42.4-128 42.4s-92.1-15.9-128-42.4V416c0-35.3 28.7-64 64-64 11.1 0 27.5 11.4 64 11.4 36.6 0 52.8-11.4 64-11.4 35.3 0 64 28.7 64 64v13.6zm30.6-27.5c-6.8-46.4-46.3-82.1-94.6-82.1-20.5 0-30.4 11.4-64 11.4S204.6 320 184 320c-48.3 0-87.8 35.7-94.6 82.1C53.9 363.6 32 312.4 32 256c0-119.1 96.9-216 216-216s216 96.9 216 216c0 56.4-21.9 107.6-57.4 146.1zM248 120c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 144c-30.9 0-56-25.1-56-56s25.1-56 56-56 56 25.1 56 56-25.1 56-56 56z" />
                      </svg>
                    </div>-->
														<div class="dropdown__menu-text font-bold">
															Jobs
														</div>
													</router-link>
												</li>
												<li class="dropdown__menu-item" @click="show = false">
													<router-link
														to="/kontakt"
														class="dropdown__menu-link"
														title="Kontakt"
													>
														<!--<div class="dropdown__menu-svg">
                      <svg viewBox="0 0 496 512">
                        <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm128 421.6c-35.9 26.5-80.1 42.4-128 42.4s-92.1-15.9-128-42.4V416c0-35.3 28.7-64 64-64 11.1 0 27.5 11.4 64 11.4 36.6 0 52.8-11.4 64-11.4 35.3 0 64 28.7 64 64v13.6zm30.6-27.5c-6.8-46.4-46.3-82.1-94.6-82.1-20.5 0-30.4 11.4-64 11.4S204.6 320 184 320c-48.3 0-87.8 35.7-94.6 82.1C53.9 363.6 32 312.4 32 256c0-119.1 96.9-216 216-216s216 96.9 216 216c0 56.4-21.9 107.6-57.4 146.1zM248 120c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 144c-30.9 0-56-25.1-56-56s25.1-56 56-56 56 25.1 56 56-25.1 56-56 56z" />
                      </svg>
                    </div>-->
														<div class="dropdown__menu-text font-bold">
															Kontakt + Zeiten
														</div>
													</router-link>
												</li>
												<li class="dropdown__menu-item" @click="show = false">
													<router-link
														to="/impressum"
														class="dropdown__menu-link"
														title="Impressum"
													>
														<!--<div class="dropdown__menu-svg">
                      <svg viewBox="0 0 496 512">
                        <path fill="currentColor" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm128 421.6c-35.9 26.5-80.1 42.4-128 42.4s-92.1-15.9-128-42.4V416c0-35.3 28.7-64 64-64 11.1 0 27.5 11.4 64 11.4 36.6 0 52.8-11.4 64-11.4 35.3 0 64 28.7 64 64v13.6zm30.6-27.5c-6.8-46.4-46.3-82.1-94.6-82.1-20.5 0-30.4 11.4-64 11.4S204.6 320 184 320c-48.3 0-87.8 35.7-94.6 82.1C53.9 363.6 32 312.4 32 256c0-119.1 96.9-216 216-216s216 96.9 216 216c0 56.4-21.9 107.6-57.4 146.1zM248 120c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 144c-30.9 0-56-25.1-56-56s25.1-56 56-56 56 25.1 56 56-25.1 56-56 56z" />
                      </svg>
                    </div>-->
														<div class="dropdown__menu-text font-bold">
															Impressum
														</div>
													</router-link>
												</li>
											</ul>
										</div>
									</transition>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				<div class="two w-1/3 flex content-center justify-center">
					<router-link to="/" class="dropdown__menu-link" title="Landing">
						<img
							class="w-64 inset-0 absolute mt-8 md:mt-0 mx-auto md:relative"
							src="@/assets/Logo.png"
						/>
					</router-link>
				</div>
				<div class="three w-1/3 justify-end text-right">
					<div class="hidden md:block w-full font-normal text-2xl">
						TERMINE BUCHEN:
					</div>
					<div class="hidden md:block w-full font-light text-2xl">
						040 38633581 |
						<a
							class="underline hover:bg-white hover:text-black inline-block"
							href="https://phorest.com/book/salons/gronebergschwestern"
							target="_blank"
						>
							ONLINE
						</a>
					</div>
					<div class="block md:hidden w-full font-light">
						<a href="tel:04038633581">040 38633581</a>
						<br />
						<a
							class="underline"
							href="https://phorest.com/book/salons/gronebergschwestern"
							target="_blank"
						>
							ONLINEBUCHUNG
						</a>
					</div>
				</div>
			</nav>
		</div>

		<div class="router-view mt-64 overflow-x-hidden mx-10 md:mx-0 mb-16">
			<transition name="slide-fade" mode="out-in">
				<router-view></router-view>
			</transition>
			<div class="flex justify-center mt-16">
				<a
					href="https://www.instagram.com/gronebergschwestern/"
					target="_blank"
				>
					<img class="h-4 mr-2" :src="require('@/assets/instagram.png')" />
				</a>
				<a href="https://www.facebook.com/gronebergschwestern/" target="_blank">
					<img class="h-4" :src="require('@/assets/facebook.png')" />
				</a>
			</div>
		</div>

		<modal ref="modalName">
			<template v-slot:header>
				<h1>Wichtige Mitteilung</h1>
			</template>

			<template v-slot:body>
				<p class="uppercase text-2xl mt-16">
					<span class="text-3xl">Krankheitssymptome?</span>
					<br />
					<br />
					Liebe Kundinnen und Kunden,
					<br />
					Zur Erhaltung Eurer und unserer
					<u>Gesundheit</u>
					bitten wir Euch, bei
					<u>Erkältungssymptomen</u>
					oder anderen
					<u>Krankheitsanzeichen zuhause zu bleiben</u>
					. Wir vereinbaren gerne einen neuen Termin, wenn es Euch besser geht.
					<u>Unser Team kann Krankheitsausfälle nur schwer kompensieren.</u>
					<br />
					<br />
					Danke für Euer Verständnis.
				</p>
			</template>

			<template v-slot:footer></template>
		</modal>
	</div>
</template>

<script>
	const querystring = require("querystring");
	import Modal from "@/components/Modal";
	export default {
		name: "HelloWorld",
		components: {
			Modal,
		},
		data() {
			return {
				show: false,
				sent: false,
				showModal: true,
				form: {
					name: "",
					mail: "",
					nachricht: "",
				},

				view: {
					atTopOfPage: true,
				},
			};
		},

		// a beforeMount call to add a listener to the window
		beforeMount() {
			window.addEventListener("scroll", this.handleScroll);
		},
		mounted() {
			//this.$refs.modalName.openModal()
		},
		methods: {
			// the function to call when the user scrolls, added as a method
			onItemChanged(/*event, currentItem, lastActiveItem*/) {
				// here you have access to everything you need regarding that event
			},
			handleScroll() {
				// when the user scrolls, check the pageYOffset
				if (window.pageYOffset > 0) {
					// user is scrolled
					if (this.view.atTopOfPage) this.view.atTopOfPage = false;
				} else {
					// user is at top of page
					if (!this.view.atTopOfPage) this.view.atTopOfPage = true;
				}
			},
			onSubmit(e) {
				e.preventDefault();
				if (this.name != "" && this.mail != "" && this.nachricht != "") {
					this.axios
						.post("mail.php", querystring.stringify(this.form))
						// eslint-disable-next-line no-unused-vars
						.then((res) => {
							this.sent = true;
						});
				}
			},
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	nav {
		z-index: 10;
	}

	nav.scrolled {
		@apply shadow-2xl;
		border-bottom: 0px;
	}
	.glass {
		@apply bg-black;
		background-color: rgba(0, 0, 0, 0.7);
		-webkit-backdrop-filter: blur(15px);
		backdrop-filter: blur(15px);
	}
	.active {
		@apply font-normal;
	}

	.header__nav {
		position: relative;
	}
	.header__navbar {
		display: -webkit-box;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: end;
		justify-content: flex-end;
	}
	.header__item {
		padding: 1rem;
	}
	.header--icon {
		width: 1.65rem;
		height: 1.65rem;
	}
	.header--button {
		top: 0;
		right: 0;
		position: absolute;
		margin: 0;
		padding: 0;
		color: white;
		cursor: pointer;
		border: 1px solid transparent;
		background-color: transparent;
	}
	.header--button:hover {
		color: gray;
	}
	.header--button:focus {
		outline: 0;
	}

	.dropdown__menu {
		top: 100%;
		left: 0;
		position: absolute;
		z-index: 10;
		min-width: 300px;
		margin-top: 1rem;
		overflow-y: auto;
		padding: 0.5rem 0rem 0.5rem 0rem;
		/*border-radius: 12px;*/
		@apply bg-thegray text-white;
		background-clip: padding-box;
	}
	.dropdown__menu-link {
		display: -webkit-box;
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: start;
		justify-content: flex-start;
		text-decoration: none;
		@apply text-white;
		/*border-radius: 0 50px 50px 0;*/
	}
	.dropdown__menu-link:hover {
		color: #000;
	}
	.dropdown__menu-svg {
		width: 1.5rem;
		height: 1.5rem;
	}
	.dropdown__menu-text {
		font-weight: 300;
		margin-left: 1rem;
		margin-right: 1rem;
		@apply text-sm uppercase;
	}

	.slide-fade-enter-active,
	.slide-fade-leave-active {
		-webkit-transition: all 0.6s;
		transition: all 0.6s;
	}

	.slide-fade-enter,
	.slide-fade-leave-active {
		opacity: 0;
	}

	.slide-fade-enter {
		-webkit-transform: translateX(31px);
		transform: translateX(31px);
	}

	.slide-fade-leave-active {
		-webkit-transform: translateX(-31px);
		transform: translateX(-31px);
	}

	.dropdown-enter-active,
	.dropdown-leave-active {
		-webkit-transition: all 1s;
		transition: all 1s;
	}

	.dropdown-enter,
	.dropdown-leave-to {
		opacity: 0;
		-webkit-transform: translateY(30px);
		transform: translateY(30px);
	}

	.main {
		margin: 6rem;
		display: -webkit-box;
		display: flex;
		flex-wrap: wrap;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: center;
		justify-content: center;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
	}
	.main__subtitle {
		color: #4f5959;
		font-weight: 100;
		margin-bottom: 2rem;
	}
	.main__link {
		margin-right: 1.5rem;
		display: inline-block;
	}
	.main__link:last-child {
		margin-right: 0;
	}
	.main__svg {
		width: 2rem;
		height: 2rem;
		opacity: 0.6;
		color: #94a2b0;
	}
	.main__svg:hover {
		color: #ffad1f;
	}
	.more {
	}
	.more:hover {
		@apply underline;
	}
	.slither-enter-active,
	.slither-leave-active {
		transition: transform 1s;
	}

	.slither-enter,
	.slither-leave-to {
		transform: translateX(-100%);
	}

	.slither-enter-to,
	.slither-leave {
		transform: translateX(0);
	}
</style>
